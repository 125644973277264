<template>
  <div>
    <template v-if="task.type === 'approvement_request'">
      <p><b>Сумма Ваших заказов превышает 4000 рублей.</b></p>
      <p>
        Пожалуйста,
        <a href="#/verification" class="task-link">заполните форму</a> и
        прикрепите подтверждающие документы (фото личных документов: разворота
        паспорта с серией и номером, ИНН).
      </p>
      <p>После проверки документов Вы сможете снова выбирать призы.</p>
    </template>
    <template v-else-if="task.type === 'act_request'">
      <template v-if="task.signing_method === 'manual'">
        <p>
          Уважаемый участник мотивационной Программы &laquo;Портал &laquo;{{
            this.$store.state.main.title
          }}&raquo;,
          <b
            >стоимость полученных Вами призов превысила освобожденную
            от&nbsp;обложения НДФЛ сумму, установленную в&nbsp;п. 28 ст. 217
            НК&nbsp;РФ&nbsp;(в&nbsp;размере 4000&nbsp;рублей).</b
          >
        </p>
        <p>
          Представитель Организатора мероприятия, по&nbsp;Вашему поручению, как
          Победителя, в&nbsp;соответствии со&nbsp;ст. 226
          НК&nbsp;РФ&nbsp;удерживает и&nbsp;перечисляет в&nbsp;бюджет налог
          на&nbsp;доходы физического лица (НДФЛ), который взимается
          по&nbsp;ставке 13%. Никаких иных налоговых обязанностей в&nbsp;связи
          с&nbsp;получением призов у&nbsp;Вас, как у&nbsp;Победителя,
          не&nbsp;возникает, налог уплачивается за&nbsp;счет Организатора
          Программы.
        </p>
        <p>
          Подписывая настоящий Акт, Вы&nbsp;соглашаетесь на&nbsp;удержание,
          перечисление в&nbsp;бюджет НДФЛ и&nbsp;подтверждаете достоверность
          и&nbsp;принадлежность Вам указанных в&nbsp;Акте персональных
          и&nbsp;иных данных. В&nbsp;соответствии с&nbsp;Правилами Программы
          Вы&nbsp;обязаны подписать Акт и&nbsp;загрузить его на&nbsp;<a
            :href="`#/upload/${task.id}`"
            class="task-link"
            >специальной странице загрузки</a
          >
          <b>в&nbsp;течение 5 рабочих дней со&nbsp;дня получения Акта</b>,
          а&nbsp;оригинал направить почтовым отправлением (или лично)
          по&nbsp;почтовому адресу: 123458, г.&nbsp;Москва,
          ул.&nbsp;Твардовского, д.8, стр.&nbsp;1, Технопарк
          &laquo;Строгино&raquo;, корпус &laquo;Г&raquo;, офис &#8470;&nbsp;313.
          В&nbsp;противном случае выдача призов на&nbsp;<nobr>промо-сайте</nobr>
          для Вас не&nbsp;будет возобновлена.
        </p>
        <p>
          <b
            >Вам необходимо
            <a
              :href="`#/download-act/${task.meta.year}/${task.id}`"
              class="task-link"
              >скачать акт</a
            >, подписать его и отправить по&nbsp;<a
              :href="`#/upload/${task.id}`"
              class="task-link"
              >ссылке</a
            >.</b
          >
        </p>
      </template>
      <template v-else>
        <p>
          Уважаемый участник мотивационной Программы &laquo;Портал
          &laquo;{{this.$store.state.main.title}}&raquo;,
          <b
            >стоимость полученных Вами призов превысила освобожденную
            от&nbsp;обложения НДФЛ сумму, установленную в&nbsp;п. 28 ст. 217
            НК&nbsp;РФ&nbsp;(в&nbsp;размере 4000&nbsp;рублей).</b
          >
        </p>
        <p>
          Представитель Организатора мероприятия, по&nbsp;Вашему поручению, как
          Победителя, в&nbsp;соответствии со&nbsp;ст. 226
          НК&nbsp;РФ&nbsp;удерживает и&nbsp;перечисляет в&nbsp;бюджет налог
          на&nbsp;доходы физического лица (НДФЛ), который взимается
          по&nbsp;ставке 13%. Никаких иных налоговых обязанностей в&nbsp;связи
          с&nbsp;получением призов у&nbsp;Вас, как у&nbsp;Победителя,
          не&nbsp;возникает, налог уплачивается за&nbsp;счет Организатора
          Программы.
        </p>
        <p>
          Подписывая настоящий Акт, Вы&nbsp;соглашаетесь на&nbsp;удержание,
          перечисление в&nbsp;бюджет НДФЛ и&nbsp;подтверждаете достоверность
          и&nbsp;принадлежность Вам указанных в&nbsp;Акте персональных
          и&nbsp;иных данных. В&nbsp;соответствии с&nbsp;Правилами Программы
          Вы&nbsp;обязаны подписать Акт. В&nbsp;противном случае выдача призов
          на&nbsp;промо-сайте для Вас не&nbsp;будет возобновлена.
        </p>
        <TaskSmsSigning :task-id="task.id"/>
      </template>
    </template>
  </div>
</template>
<script>
import TaskSmsSigning from "./TaskSmsSigning.vue";
export default {
  components: { TaskSmsSigning },
  props: ["task"],
};
</script>
